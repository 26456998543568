import { useEffect } from 'react'

const ThreeDSecure = () => {
  useEffect(() => {
    if (window && typeof window !== undefined) {
      let urlParams = new URLSearchParams(window.location.search)
      window.top.dispatchEvent(
        new CustomEvent('3DS-authentication-complete', {
          detail: {
            clientSecret: urlParams.get('client_secret'),
            livemode: urlParams.get('livemode'),
            source: urlParams.get('source'),
          },
        })
      )
    }
  }, [])
  return null
}

export default ThreeDSecure
